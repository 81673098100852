import React from "react";
import { ListGroup } from 'react-bootstrap';

{/* 세팅 페이지 */}
export default function settingPage() {
    
  const url = "http://221.154.163.203/download/"

  const downloadFile = (fileUrl) => {
    var a = document.createElement("a");
    
    var filePath = fileUrl.split('/');
    var fileName = filePath[filePath.length - 1]
    a.setAttribute('href', url + fileUrl);
    a.setAttribute('download', fileName);
    a.setAttribute('target', '_blank');
    a.click();

    return true;
    
  }

  return (
    <body>
        <div>
          <ListGroup>
              <ListGroup.Item variant="primary" style={{fontSize:30}}>설정 파일 목록</ListGroup.Item>
              <ListGroup.Item variant="success" onClick={() =>  downloadFile("apk/enav_filedownloader_1.3.2.apk")} style={{fontSize:20}}>바다길잡이 파일 다운로더 1.3.2</ListGroup.Item>
              <ListGroup.Item variant="danger" onClick={() =>  downloadFile("apk/BadaGiljabiLauncher_2.3.8_A_Ship.apk")} style={{fontSize:20}}>바다길잡이 런처 설치 2.3.8(선박)</ListGroup.Item>
              {/* <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/BadaGiljabiLauncher.apk")} style={{fontSize:20}}>바다길잡이 런처 설치 2.3.7(선박)</ListGroup.Item> */}
              <ListGroup.Item variant="danger" onClick={() =>  downloadFile("apk/BadaGiljabiLauncher_Factory.apk")} style={{fontSize:20}}>바다길잡이 런처 설치(공장)</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/BadaGiljabiLauncher_eNavCenter.apk")} style={{fontSize:20}}>바다길잡이 런처 설치(eNav센터)</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/launcher_210326_2.3.7_REGIONAL.apk")} style={{fontSize:20}}>바다길잡이 런처 설치(권역센터용)</ListGroup.Item>
              <ListGroup.Item variant="success" onClick={() =>  downloadFile("apk/iPerf.apk")} style={{fontSize:20}}>무선국 검사 app iPerf 설치</ListGroup.Item>
              <ListGroup.Item variant="success" onClick={() =>  downloadFile("apk/arubanetworks-arubautilities-184.apk")} style={{fontSize:20}}>무선국 검사 app Aruba Utilitie 설치</ListGroup.Item>
              {/* <ListGroup.Item variant="success" onClick={() =>  downloadFile("other/S101_NEW_TEXT.zip")} style={{fontSize:20}}>육상지명 데이터</ListGroup.Item> */}
              {/* <ListGroup.Item variant="primary" onClick={() =>  downloadFile("other/enav.zip")} style={{fontSize:20}}>전자해도 데이터_2021</ListGroup.Item> */}
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/13인치 인증용 SW.apk")} style={{fontSize:20}}>13인치 인증용 SW</ListGroup.Item>
              {/* <ListGroup.Item variant="danger" onClick={() =>  downloadFile("apk/Tmmc_Conference_Limit.apk")} style={{fontSize:20}}>이네비고도화 UC 테스트 앱</ListGroup.Item> */}
              <ListGroup.Item variant="success" onClick={() =>  downloadFile("other/ecs_logo.png")} style={{fontSize:20}}>ECS logo 파일</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("other/GMT.zip")} style={{fontSize:20}}>1F20100015 파일</ListGroup.Item>
              <ListGroup.Item variant="success" onClick={() =>  downloadFile("other/v_pass_image.png")} style={{fontSize:20}}>해경 브이패스 배경화면</ListGroup.Item>
            </ListGroup>
        </div>
    </body>

  );
}
