import React from "react";
import { ListGroup } from 'react-bootstrap';

{/* 유틸리티 페이지 */}
export default function utilPage() {
    
  const url = "http://221.154.163.203/download/"

  const downloadFile = (fileUrl) => {
    var a = document.createElement("a");
    
    var filePath = fileUrl.split('/');
    var fileName = filePath[filePath.length - 1]
    a.setAttribute('href', url + fileUrl);
    a.setAttribute('download', fileName);
    a.setAttribute('target', '_blank');
    a.click();

    return true;
    
  }

  return (
    <body>
        <div>
          <ListGroup>
              <ListGroup.Item variant="primary" style={{fontSize:30}}>설정 파일 목록</ListGroup.Item>
              <ListGroup.Item variant="success" onClick={() =>  downloadFile("apk/LteM_PreTest.apk")} style={{fontSize:20}}>LTE-M 사전 테스트 프로그램</ListGroup.Item>
              <ListGroup.Item variant="danger" onClick={() =>  downloadFile("apk/7zipper-2-0-2-9-30.apk")} style={{fontSize:20}}>7지퍼</ListGroup.Item>
              <ListGroup.Item variant="success" onClick={() =>  downloadFile("other/EcsConfig.db")} style={{fontSize:20}}>13인치 흥보용 단말기 DB</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/serialtest.apk")} style={{fontSize:20}}>13인치시리얼테스트</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("other/1kHz_44100Hz_16bit_30sec.mp3")} style={{fontSize:20}}>인증용tone파일</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/13인치 테스트 앱.apk")} style={{fontSize:20}}>13인치 테스트앱</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/Mobizen_v3.9.0.20_mod_apkdone.com.apk")} style={{fontSize:20}}>모비즌</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/BadaGiljabi_2.1.1_test.apk")} style={{fontSize:20}}>바다길잡이 테스트</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/Recorder_v3.7.5.3.apk")} style={{fontSize:20}}>temp uc</ListGroup.Item>
              <ListGroup.Item variant="primary" onClick={() =>  downloadFile("apk/fish_test.apk")} style={{fontSize:20}}>어탐 테스트 어플리케이션</ListGroup.Item>
            </ListGroup>
        </div>
    </body>

  );
}
