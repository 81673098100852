import React from "react";
import { ListGroup } from 'react-bootstrap';

{/* OS 페이지 */}
export default function osPage() {
  
  const url = "http://221.154.163.203/download/"

  const downloadFile = (fileUrl) => {
    var a = document.createElement("a");
    
    var filePath = fileUrl.split('/');
    var fileName = filePath[filePath.length - 1]
    a.setAttribute('href', url + fileUrl);
    a.setAttribute('download', fileName);
    a.setAttribute('target', '_blank');
    a.click();

    return true;
    
  }

  return (
    <body>
        <div>
          <ListGroup>
              <ListGroup.Item variant="primary" style={{fontSize:30}}>런처 파일 목록</ListGroup.Item>
                <ListGroup.Item variant="danger" onClick={() =>  downloadFile("other/13/update.zip")} style={{fontSize:20}}>13인치 펌웨어 업데이트 버전</ListGroup.Item>
                <ListGroup.Item variant="danger" onClick={() =>  downloadFile("other/10/update.zip")} style={{fontSize:20}}>10인치 펌웨어 업데이트 버전</ListGroup.Item>
              </ListGroup>
        </div>
    </body>

  );
}
