import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { Form, Alert } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

{/* NAS 페이지 */}
export default function NasPage() {
    const [formData, setFormData] = useState({
        serial_number_input: ''
    });
    const [formData2, setFormData2] = useState({
        serial_number_input2: '',
        ucnumber_input: '',
        license_input: '',
        password : ''
    });
    const [result, setResult] = useState({ result_get_ucnumber: null, result_get_license: null });
    const [result2, setResult2] = useState({ result_get_ucnumber2: null, result_get_license2: null});
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChange2 = (event) => {
        const { name, value } = event.target;
        setFormData2((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChange3 = (event) => {
        const { name, value } = event.target;
        setFormData2((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChange4 = (event) => {
        const { name, value } = event.target;
        setFormData2((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setResult({ result_get_ucnumber: null, result_get_license: null });

        try {
            const response = await fetch('http://221.154.163.203:6060/api/nasUcLicenseGET/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            setResult({ result_get_ucnumber: data.result_get_ucnumber, result_get_license: data.result_get_license });
        } catch (error) {
            setError(error.message);
        }
        console.log(result);
    };
    const handleSubmit2 = async (event) => {
        event.preventDefault();
        setError(null);
        setResult2({ result_get_ucnumber2: null, result_get_license2: null });
        formData2.password = password
        try {
            const response = await fetch('http://221.154.163.203:6060/api/nasUcLicensePOST/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData2),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data2 = await response.json();
            setResult2({ result_get_ucnumber2: data2.result_get_ucnumber2, result_get_license2: data2.result_get_license2 });
        } catch (error) {
            setError(error.message);
        }
        console.log(result2);
    };
  
  return (
    <body>
        <div>
            {error && <Alert variant="danger" className="mt-3">잘못된 번호거나 NAS에 해당 정보가 없습니다. 혹은 비밀번호를 확인해주세요.</Alert>}
            <h>NAS 정보 조회</h>
            <Form onSubmit={handleSubmit}>
                <InputGroup className="mb-3">
                    
                        <Form.Control
                            type="text"
                            name="serial_number_input"
                            placeholder="시리얼 넘버"
                            aria-label="시리얼 넘버"
                            aria-describedby="basic-addon2"
                            value={formData.serial_number_input}
                            onChange={handleChange}
                            required
                        />
                        <Button variant="outline-secondary" id="button-addon2" type="submit">
                        조회
                        </Button>
                    </InputGroup>
            </Form>

            {result.result_get_ucnumber !== null && result.result_get_license !== null && (
                        <Alert variant="success" className="mt-3">
                            <p>UC 번호 : {result.result_get_ucnumber}</p>
                            <p>해도 라이선스 : {result.result_get_license}</p>
                        </Alert>
                    )}
                    
        </div>
        <div>
            <Form onSubmit={handleSubmit2}>
            <h>NAS 정보 변경</h>
                <InputGroup className="mb-3">
                        <Form.Control
                            type="text"
                            name="serial_number_input2"
                            placeholder="시리얼 넘버"
                            aria-label="시리얼 넘버"
                            aria-describedby="basic-addon2"
                            value={formData2.serial_number_input2}
                            onChange={handleChange2}
                            required
                        />
                        <Form.Control
                            type="text"
                            name="ucnumber_input"
                            placeholder="UC 번호"
                            aria-label="UC 번호"
                            aria-describedby="basic-addon2"
                            value={formData2.ucnumber_input}
                            onChange={handleChange3}
                            required
                        />
                        <Form.Control
                            type="text"
                            name="license_input"
                            placeholder="해도 라이선스"
                            aria-label="해도 라이선스"
                            aria-describedby="basic-addon2"
                            value={formData2.license_input}
                            onChange={handleChange4}
                            required
                        />
                        <Form.Control
                                type="password"
                                name="password"
                                placeholder="비밀번호"
                                onChange={handlePasswordChange}
                                required
                        />
                        <Button variant="outline-secondary" id="button-addon2" type="submit">
                        변경
                        </Button>
                    </InputGroup>
            </Form>

            {result2.result_get_ucnumber2 !== null && result2.result_get_license2 !== null && (
                        <Alert variant="success" className="mt-3">
                            <p>변경된 UC 번호 : {result2.result_get_ucnumber2}</p>
                            <p>변경된 해도 라이선스 : {result2.result_get_license2}</p>
                        </Alert>
                    )}
        </div>
    </body>

  );
}
